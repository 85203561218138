// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_modal__nXPbu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    animation: modal_slide-down__IMWLY 500ms ease-out forwards;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_icons__pJ83X {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: rgb(255, 87, 34);
    font-size: 2rem;
    border-radius: 50%;
    z-index: 10000;
    cursor: pointer;
}

.modal_fullScreenRow__BWr2G {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.modal_fullVideoContainer__40rQ8 {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_fullVideo__Fmya6 {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

@keyframes modal_slide-down__IMWLY {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
`, "",{"version":3,"sources":["webpack://./src/views/Css/modal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,0DAA6C;IAC7C,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,UAAU;QACV,4BAA4B;IAChC;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ","sourcesContent":[".modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.9);\n    animation: slide-down 500ms ease-out forwards;\n    z-index: 1000000;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.icons {\n    position: absolute;\n    top: 1rem;\n    left: 1rem;\n    color: rgb(255, 87, 34);\n    font-size: 2rem;\n    border-radius: 50%;\n    z-index: 10000;\n    cursor: pointer;\n}\n\n.fullScreenRow {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n}\n\n.fullVideoContainer {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.fullVideo {\n    width: 100%;\n    height: 100%;\n    object-fit: cover; \n}\n\n@keyframes slide-down {\n    from {\n        opacity: 0;\n        transform: translateY(-3rem);\n    }\n    to {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `modal_modal__nXPbu`,
	"slide-down": `modal_slide-down__IMWLY`,
	"icons": `modal_icons__pJ83X`,
	"fullScreenRow": `modal_fullScreenRow__BWr2G`,
	"fullVideoContainer": `modal_fullVideoContainer__40rQ8`,
	"fullVideo": `modal_fullVideo__Fmya6`
};
export default ___CSS_LOADER_EXPORT___;
