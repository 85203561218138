
import {Outlet,useLocation } from "react-router-dom";
import './assets/scss/style.scss';
import Header from "./containers/Header";
import { Provider } from "react-redux";
import store from './store';
import { useState,useEffect } from "react";

function App(){
  const location = useLocation();
  const [showNavbar , setShowNavbar] = useState(true)

  useEffect(()=>{
    if(location.pathname ==='/login'){
      setShowNavbar(false)
    }else{
      setShowNavbar(true)
    }
  },[location.pathname]);

  return(
    <Provider store={store}>
    {showNavbar && <Header/>}
    <Outlet/>
    </Provider>
   
  )
}

export default App;
